var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoad,
          expression: "isLoad",
        },
      ],
      staticClass: "fromProcess-relav-form",
    },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "单据类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择单据类型",
                            trigger: "change",
                          },
                        ],
                        prop: "billtypeId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "单据类型",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.billtypeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billtypeId", $$v)
                              },
                              expression: "form.billtypeId",
                            },
                          },
                          "v-select2",
                          _vm.billTypeParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择流程模型",
                        rules: [
                          {
                            required: true,
                            message: "请选择流程模型",
                            trigger: "change",
                          },
                        ],
                        prop: "modelId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询流程模型",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.modelId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "modelId", $$v)
                              },
                              expression: "form.modelId",
                            },
                          },
                          "v-select2",
                          _vm.modelParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否启用", prop: "delFlag" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.delFlagOps, width: _vm.width },
                        model: {
                          value: _vm.form.delFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "delFlag", $$v)
                          },
                          expression: "form.delFlag",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
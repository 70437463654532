<template>
  <div class="fromProcess-relav-form" v-show="isLoad">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item label="单据类型" :rules="[{ required: true, message: '请选择单据类型', trigger: 'change' }]" prop="billtypeId">
            <v-select2 
                v-model="form.billtypeId"
                placeholder="单据类型"
                v-bind="billTypeParams"
                :width="width" />
          </el-form-item>

          <el-form-item label="选择流程模型" :rules="[{ required: true, message: '请选择流程模型', trigger: 'change' }]" prop="modelId">
            <v-select2 
                v-model="form.modelId"
                placeholder="查询流程模型"
                v-bind="modelParams"
                :width="width" />
          </el-form-item>

          <el-form-item label="是否启用"  prop="delFlag">
            <v-select 
                v-model="form.delFlag"
                :options="delFlagOps"
                :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { createURL, updateURL, getModelListURL, getBilltypeListURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { delFlagOps } from './map'
export default {
  name: 'processModelForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {

    return {
      width: 200,
      isLoad: false,
      submitConfig: {
        submitUrl: undefined,
        submitMethod: ''
      },
      form: {
        modelId: '',
        orgId: 0,
        delFlag: 0,
        billtypeId: 0
      },
      modelParams: {
        searchUrl: getModelListURL,
        request: {
          text: 'name',
          value: 'id'
        }
      },
      billTypeParams : {
        searchUrl: getBilltypeListURL,
        request: {
          text: 'name',
          value: 'id'
        },
        response: {
          text: 'billtypeName',
          value: 'id'
        },
      },
      delFlagOps
      
    }
  },
  
  mounted () {
    const { orgId, orgParentId, orgType } = this.$route.query
    console.log(orgId, orgType)
    this.$setBreadcrumb('新增流程')
    this.submitConfig.submitUrl = createURL
    this.submitConfig.submitMethod = 'POST'
    this.form.orgId = Number(orgId)
    this.isLoad = true
  },
  methods: {
    
    submitBefore (data) {
        return data
    }
  }
}
</script>
